import { map as _map, uniqBy as _uniqBy } from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';
import { getCalendarEvents, getCalendars } from '../crud/calendar.crud';
import { REDUCER_CALENDARS } from './conf';
import { parseEvents } from './parsers';

export const actionTypes = {
  GetCalendars: 'cms/GET_CALENDARS',
  GetCalendarEvents: 'cms/GET_CALENDAR_EVENTS',
  SetCalendarEvents: 'cms/SET_CALENDAR_EVENTS',
};

const initialState = {
  events: [],
  loading: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetCalendars:
      return { ...state, loading: true };
    case actionTypes.SetCalendarEvents: {
      return { loading: false, events: parseEvents(action.payload) };
    }
    default:
      return state;
  }
};

export const selectors = {
  getEvents: (state) => {
    return state.entities && state.entities[REDUCER_CALENDARS] ? state.entities[REDUCER_CALENDARS].events : null;
  },
};

export const actions = {
  getCalendars: (payload) => ({ type: actionTypes.GetCalendars, payload }),
  getCalendarEvents: (calendars) => ({ type: actionTypes.GetCalendarEvents, payload: calendars }),
  setCalendarEvents: (events) => ({ type: actionTypes.SetCalendarEvents, payload: events }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetCalendars, function* getCalendarsSaga(action) {
    const { data } = yield getCalendars();
    if (data) {
      let calendars = data.data;
      calendars = calendars.filter((calendar) => calendar.name !== 'personal-calendar');

      let params = {};
      params.calendar = _uniqBy(_map(calendars, 'guid'));
      params.fromDate = action.payload.fromDate;
      params.toDate = action.payload.toDate;
      params.offset = action.payload.offset;
      params.pageSize = action.payload.pageSize;
      yield put(actions.getCalendarEvents(params));
    }
  });

  yield takeLatest(actionTypes.GetCalendarEvents, function* getCalendarEventsSaga(action) {
    const { data } = yield getCalendarEvents(action.payload);

    yield put(actions.setCalendarEvents(data?.data));
  });
}
