export function loginOraculo(interval, submitLogin) {
  const { isSignedIn, getTokenId } = window;

  if (isSignedIn && isSignedIn()) {
    const tokenId = getTokenId();
    clearInterval(interval);
    submitLogin(tokenId);
  }
}

export function saveGlobalSession(sid) {
  if (window.saveGlobalSession) return window.saveGlobalSession(sid);
}

export function onClickLogin() {
  return window.getLoginButton().onclick();
}

export function onClickLogout() {
  return window.getLogoutButton().onclick();
}

export function removeCookieTokenOraculo() {
  const { isSignedIn } = window;
  console.log('onClickLogout');
  console.log(window);
  if (window?.makeLogout()) {
    return window.makeLogout();
  }
  if (isSignedIn && isSignedIn()) {
    onClickLogout();
  }
}

export function getOraculoToken() {
  return window.getTokenId();
}

export function onClickSignup() {
  return window.openSignUpPopUp();
}

export function onSetRol(rol) {
  return window.setRol(rol);
}

export function onSetUsername(username) {
  return window.setUsername(username);
}

export function checkUsername(username) {
  return window.checkUsername(username);
}
