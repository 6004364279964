import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const Icon = ({ type, title, size, color }) => {
  const [applyColor, setApplyColor] = useState(color);
  // eslint-disable-next-line no-undef
  const svgContextCore = require.context('_core/assets/icons', false, /\.svg$/);
  // eslint-disable-next-line no-undef
  const svgContextApp = require.context('app/assets/icons', false, /\.svg$/);

  const getIconSrc = () => {
    if (svgContextApp.keys().includes(`./${type}.svg`)) {
      return { src: svgContextApp(`./${type}.svg`), libComponent: 'skin' };
    } else if (svgContextCore.keys().includes(`./${type}.svg`)) {
      return { src: svgContextCore(`./${type}.svg`), libComponent: 'core' };
    } else {
      return { src: svgContextCore(`./close.svg`), libComponent: 'core' };
    }
  };

  const { src, libComponent } = getIconSrc();

  useEffect(() => {
    if (src?.ReactComponent) {
      const svgText = src.ReactComponent.toString();
      if (svgText.includes('url(#') && color === 'default') {
        setApplyColor('');
      } else {
        setApplyColor(color);
      }
    }
  }, [color, src]);

  return src ? (
    <div className="icon__wrapper">
      <div>
        <src.ReactComponent className={`icon icon--lib-${libComponent} icon--size-${size} icon--color-${applyColor} injected-svg`} title={title} />
      </div>
    </div>
  ) : null;
};

Icon.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.oneOf(['xs', 's', 'default', 'big', 'big-2', 'xl', 'xxl']),
  color: PropTypes.oneOf([
    '',
    ' ',
    'default',
    'black',
    'white',
    'primary',
    'light',
    'label',
    'red',
    'grey-01',
    'grey-02',
    'grey-03',
    'grey-04',
    'secondary',
    'original',
  ]),
};

Icon.defaultProps = {
  type: '',
  title: '',
  size: 'default',
  color: 'default',
};

export default Icon;
