import { getSetting } from '_core/crud/systemSettings.crud';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, select, takeLatest } from 'redux-saga/effects';
import { REDUCER_SYSTEM_SETTINGS } from './conf';

export const actionTypes = {
  GetSystemSettings: 'system/GET_SYSTEM_SETTINGS',
  SetSystemSettings: 'system/SET_SYSTEM_SETTINGS',
};

const initialState = {};

export const reducer = persistReducer({ storage, key: REDUCER_SYSTEM_SETTINGS }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GetSystemSettings:
      return { ...state, loading: true };

    case actionTypes.SetSystemSettings: {
      const { settings } = action.payload;
      if (settings) {
        return { ...state, settings, loading: false };
      }
      return { ...state, loading: false };
    }

    default:
      return state;
  }
});

export const selectors = {
  getSystemSettings: (state) => state[REDUCER_SYSTEM_SETTINGS]?.settings,
};

export const actions = {
  getSystemSettings: () => ({ type: actionTypes.GetSystemSettings }),
  setSystemSettings: (settings) => ({ type: actionTypes.SetSystemSettings, payload: { settings } }),
};

export function* saga() {
  yield takeLatest(actionTypes.GetSystemSettings, function* getSystemSettingsSaga() {
    try {
      // Verificar si ya tenemos los settings en el store
      const existingSettings = yield select(selectors.getSystemSettings);

      if (!existingSettings) {
        // Si no hay datos en el store, hacer la llamada a la API
        const response = yield getSetting();
        yield put(actions.setSystemSettings(response?.data?.data));
      }
    } catch (error) {
      console.error('Error fetching system settings:', error);
    }
  });
}
