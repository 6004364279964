import { InteractionStatus } from '@azure/msal-browser';
import { useAccount, useMsal } from '@azure/msal-react';
import { GoogleLogin } from '@react-oauth/google';
import { recoverAccount } from '_core/crud/auth.crud';
import Button from '_core/modules/atoms/Button';
import ErrorText from '_core/modules/atoms/ErrorText';
import InputCheckbox from '_core/modules/atoms/InputCheckbox';
import InputText from '_core/modules/atoms/InputText';
import LineWithText from '_core/modules/atoms/LineWithText';
import Loading from '_core/modules/atoms/Loading';
import Logo from '_core/modules/atoms/Logo/Logo';
import TextHtml from '_core/modules/atoms/TextHtml';
import DialogDefault from '_core/modules/components/dialogs/components/Dialog';
import ForgotPasswordForm from '_core/modules/components/forms/ForgotPasswordForm/ForgotPasswordForm';
import LoginOraculo from '_core/modules/components/ssoOraculo/LoginOraculo';
import { loginOraculo } from '_core/modules/components/ssoOraculo/serviceOraculo';
import * as coreEntities from '_core/store/index';
import { successResponse } from '_core/utils/utils';
import { PRIVACY, TERMS } from 'app/config/config';
import { defaultConfig } from 'app/config/environment';
import initLayoutConfig from 'app/config/layout/LayoutConfig';
import { getUrlSupport } from 'app/utils/utils';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { setTimeout } from 'timers';
const POLITICS = { LEGAL: 'legal', PRIVACY: 'privacy' };

const FORGOT_PASSWORD_STATE = { INITIAL: 'initial', LOADING: 'loading', SUCCESS: 'success' };

const LoginForm = (props) => {
  const { addToast } = useToasts();
  const { instance, accounts, inProgress } = useMsal();

  const { register, handleSubmit, watch, errors } = useForm();
  const { t, legal, privacy, onSubmit, onSSO, errorMsg, loading, showRememberMe, showForgotPassword, onRegister } = props;
  const language = useSelector((state) => coreEntities.i18n.selectors.getLanguage(state));
  const [showPolitics, setShowPolitics] = useState(false);
  const [showForgotPasswordDialog, setShowForgotPasswordDialog] = useState(false);
  const [forgotPasswordState, setForgotPasswordState] = useState(FORGOT_PASSWORD_STATE.INITIAL);
  const [visible, setVisible] = useState(false);

  let lang = language && Object.keys(PRIVACY).includes(language) ? language : 'es';

  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    if (inProgress === InteractionStatus.None && account) {
      // Get access token silently for logged in user
      instance
        .acquireTokenSilent({
          scopes: ['User.Read'],
          account: account,
        })
        .then((response) => {
          if (response && response.accessToken) {
            onSSO(response.accessToken, 'microsoft');
          }
        })
        .catch((error) => {
          console.error('Token acquisition failed:', error);
        });
    }
  }, [inProgress, account]);

  useEffect(() => {
    if (showPolitics === POLITICS.PRIVACY) {
      setTimeout(() => {
        const privacyId = document.getElementById('privacy-policy');
        if (privacyId) {
          privacyId.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }, 100);
    }
  }, [showPolitics]);

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      const interval = setInterval(() => {
        loginOraculo(interval, onSSO);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [inProgress]);

  const onLoginMicrosoft = () => {
    // Check if running on iOS mobile device
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (isIOS) {
      // For iOS devices, use redirect login instead of popup
      instance.loginRedirect({
        scopes: ['User.Read'],
        redirectStartPage: window.location.href,
      });
      return;
    }
    instance.loginPopup({
      scopes: ['User.Read'],
    });
  };

  const onLogin = (data) => {
    if (onSubmit) onSubmit(data.email, data.password);
  };

  const onLoginSSO = (token) => {
    if (onSSO) onSSO(token, 'google');
  };

  const closeShowForgotPasswordDialog = () => {
    setShowForgotPasswordDialog(false);
    setForgotPasswordState(FORGOT_PASSWORD_STATE.INITIAL);
  };

  async function onSendChangePassword(email) {
    setForgotPasswordState(FORGOT_PASSWORD_STATE.LOADING);
    const response = await recoverAccount(email, { link: defaultConfig.BASE_URL_ + 'recover-password?token=[TOKEN]&lang=[LANG]' });
    if (successResponse(response)) {
      setForgotPasswordState(FORGOT_PASSWORD_STATE.SUCCESS);
    } else {
      setForgotPasswordState(FORGOT_PASSWORD_STATE.INITIAL);
      addToast(t(`login:User not exist`), {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  function goToSupport() {
    window.open(getUrlSupport(navigator.language), '_blank');
  }

  function onShowPolitics(selection) {
    const politics = selection === POLITICS.PRIVACY ? PRIVACY[lang] : TERMS[lang];
    if (politics?.endsWith('pdf')) {
      if (selection === POLITICS.PRIVACY) window.open(defaultConfig.BASE_URL_ + 'privacy', '_blank');
      if (selection === POLITICS.LEGAL) window.open(defaultConfig.BASE_URL_ + 'terms', '_blank');
    } else {
      setShowPolitics(selection);
    }
  }

  return (
    <div className="login-form">
      {forgotPasswordState === FORGOT_PASSWORD_STATE.LOADING && <Loading />}
      <div className="login-form__logo">
        <div className="login-form__item">
          <Logo size="big" />
        </div>
      </div>
      <form onSubmit={handleSubmit(onLogin)} className="login-form__form">
        <div className="login-form__item">
          <h3 className="login-form__title">{t('Log in')}</h3>
        </div>
        <div className="login-form__item">
          <InputText
            icon="user"
            iconLeft={true}
            placeholder={t('auth:Username')}
            error={errors.email && <ErrorText text={t('common:Required parameter')} />}
            label={t('auth:User')}
            name="email"
            referer={register({ required: true })}
          />
        </div>
        <div className="login-form__item">
          <InputText
            icon={visible ? 'visible' : 'visible-off'}
            onClickIcon={() => setVisible(!visible)}
            iconLeft={true}
            label={t('auth:Password')}
            type={visible ? 'text' : 'password'}
            placeholder={t('auth:Password')}
            error={errors.password && <ErrorText text={t('common:Required parameter')} />}
            name="password"
            referer={register({ required: true })}
          />
        </div>
        <div className="login-form__item login-form__item--subactions">
          {showRememberMe && (
            <div className="login-form__checkbox-container">
              <InputCheckbox label={t('Remember me')} />
            </div>
          )}
          {showForgotPassword && (
            <div className="login-form__text login-form__text--forget" onClick={() => setShowForgotPasswordDialog(true)}>
              {t('Forgot your password?')}
            </div>
          )}
        </div>
        <div className="login-form__item">
          <Button testid="btn-login" text={t('Log in')} loading={loading} type="submit" />
        </div>
        {
          <>
            <div className="login-form__item">
              <LineWithText text={t('Or')} />
            </div>

            <div className="login-form__third-parties">
              <div className="login-form__item">
                <Button
                  testid="btn-sso-microsoft"
                  color="white"
                  text={t('login:Login with Microsoft')}
                  icon="microsoft"
                  iconPosition="left"
                  onClick={onLoginMicrosoft}
                />
              </div>

              {initLayoutConfig.login.showOraculo && (
                <div className="login-form__item">
                  <LoginOraculo text={t('login:Login with Polp')} />
                </div>
              )}

              <div className="login-form__item">
                <GoogleLogin
                  width="376px"
                  text="signin_with"
                  onSuccess={(credentialResponse) => {
                    // console.log(getTokenUserDetails(credentialResponse.credential));
                    onLoginSSO(credentialResponse.credential);
                  }}
                  onError={(response) => {
                    console.log('google fail ', response);
                  }}
                  locale={i18next.language}
                />
              </div>
            </div>
          </>
        }
        <div className="login-form__politics">
          <div className="login-form__legal-advice" onClick={() => onShowPolitics(POLITICS.LEGAL)}>
            {t('login:Legal advice')}
          </div>
          <div className="login-form__privacy" onClick={() => onShowPolitics(POLITICS.PRIVACY)}>
            {t('login:Privacy')}
          </div>
          <div className="login-form__support" onClick={() => goToSupport()}>
            {t('common:Technical support')}
          </div>
        </div>
      </form>
      <div className="login-form__signup">
        <span className="login-form__text">
          {t('If you don’t have an account yet,')}

          <span className="login-form__link" onClick={onRegister}>
            {' '}
            {t('register here')}
          </span>
        </span>
      </div>
      <DialogDefault
        size="xl"
        className="dialog-terms"
        title={t(showPolitics === POLITICS.PRIVACY ? 'login:Privacy' : 'login:Legal advice').toUpperCase()}
        open={showPolitics}
        onClose={() => setShowPolitics(null)}
      >
        <TextHtml text={showPolitics === POLITICS.PRIVACY ? PRIVACY[lang] : TERMS[lang]} />
      </DialogDefault>
      <DialogDefault
        size="xs"
        className="dialog-forgot-password"
        title={t('recover:recover password')}
        open={showForgotPasswordDialog}
        onClose={closeShowForgotPasswordDialog}
      >
        <ForgotPasswordForm t={t} state={forgotPasswordState} onCancel={closeShowForgotPasswordDialog} onSuccess={onSendChangePassword} />
      </DialogDefault>
    </div>
  );
};

export default withTranslation(['login', 'auth', 'common', 'recover'])(LoginForm);
