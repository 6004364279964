import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { isNativeAppWebview } from '_core';
import { getCurrentLanguage } from '_core/utils/languages';
import { isTeams } from '_core/utils/teamsTab';
import { DEFAULT_LANG, clientIdGoogle } from 'app/config/config';
import { ConfigCatProvider, PollingMode } from 'configcat-react';
import { gapi } from 'gapi-script';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { ToastProvider } from 'react-toast-notifications';
import { PersistGate } from 'redux-persist/integration/react';
import AppContainer from './AppContainer';
import { I18nProvider, LayoutSplashScreen } from './_core';
import ToastCustom from './_core/modules/atoms/Toast';
import DialogInfo from './_core/modules/components/dialogs/DialogInfo/DialogInfo';
import { msalConfig } from './app/config/authConfig';
import InitsContainer from './app/containers/InitsContainer';
import SocketsContainer from './app/containers/SocketsContainer';
import PolpPopup from './app/modules/components/dialogs/PolpDialogs/PolpPopup/PolpPopup';
import { Routes } from './app/router/Routes';
import './app/utils/skinAssets/icons';
import './app/utils/skinAssets/icons-gradient';
import './app/utils/skinAssets/images';

const msalInstance = new PublicClientApplication(msalConfig);

export default function App({ store, persistor, basename }) {
  const [googleClientId, setGoogleClientId] = useState(clientIdGoogle[window.location.origin]);
  const lang = getCurrentLanguage() === 'vl' ? 'ca' : getCurrentLanguage() || DEFAULT_LANG;
  moment.locale(lang);

  const [isPolpHour, setIsPolpHour] = useState(false);

  gapi.load('client:auth2', () => {
    gapi.auth2.init({
      clientId: clientIdGoogle[window.location.origin],
      plugin_name: 'chat',
    });
  });

  useEffect(() => {
    moment.locale(lang);
  }, [lang]);

  useEffect(() => {
    setGoogleClientId(clientIdGoogle[window.location.origin]);
    // let currentHour = moment();
    // if (!currentHour.isBetween(moment('08:00', 'HH:mm'), moment('17:00', 'HH:mm'))) {
    //   setIsPolpHour(true);
    // } else {
    //   setIsPolpHour(false);
    // }
  }, []);

  return (
    /* Provide Redux store */
    <MsalProvider instance={msalInstance}>
      <GoogleOAuthProvider clientId={googleClientId}>
        <Provider store={store}>
          <ConfigCatProvider sdkKey="OBfcCG_3iEiBYr3gPQEhRQ/DihKS7xW00aPdijComkQDQ" pollingMode={PollingMode.LazyLoad}>
            {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
            <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
              {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
              <React.Suspense fallback={<LayoutSplashScreen />}>
                {/* Override `basename` (e.g: `homepage` in `package.json`) */}
                <BrowserRouter basename={basename}>
                  {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                  <LastLocationProvider>
                    {/* Provide Metronic theme overrides. */}
                    {/*<ThemeProvider>*/}
                    {/* Provide `react-intl` context synchronized with Redux state.  */}
                    <I18nProvider>
                      {/* Provide `react-toast-notifications` which provides context for the Toast descendants  */}
                      <DialogInfo />
                      {!isTeams() && !isNativeAppWebview() && isPolpHour && <PolpPopup />}
                      <ToastProvider placement="bottom-left" components={{ Toast: ToastCustom }}>
                        {/* Render routes with provided `Layout`. */}
                        <Switch>
                          <Routes />
                        </Switch>
                        <SocketsContainer />
                        <AppContainer />
                        <InitsContainer />
                      </ToastProvider>
                    </I18nProvider>
                    {/*</ThemeProvider>*/}
                  </LastLocationProvider>
                </BrowserRouter>
              </React.Suspense>
            </PersistGate>
          </ConfigCatProvider>
        </Provider>
      </GoogleOAuthProvider>
    </MsalProvider>
  );
}
